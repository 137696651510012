<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            จ่ายค่าแรง
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-date v-model="Wage.WageDate"></v-date>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  label="จำนวนเงิน"
                  placeholder="จำนวนเงิน"
                  type="number"
                  dense
                  v-model="Wage.WageTotal"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  label="รับเงิน"
                  placeholder="รับเงิน"
                  type="number"
                  @change="ValidateNumber()"
                  v-model="Wage.WageRecive"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  disabled
                  label="คงเหลือ"
                  placeholder="คงเหลือ"
                  type="number"
                  dense
                  :value="Number(Wage.WageTotal) - Number(Wage.WageRecive)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-textarea
                  label="รายละเอียด"
                  placeholder="รายละเอียด"
                  dense
                  rows="1"
                  v-model="Wage.WageRemark"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="1" lg="1">
                <v-btn color="primary" width="100%" @click="Insert()">
                  <v-icon>fa fa-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            ประวัติ
          </v-card-title>
          <v-data-table
            :items-per-page="-1"
            :headers="headers"
            :items="desserts"
            class="elevation-1"
          >
            <template v-slot:item.action="{ item }">
              <v-btn class="mx-2 red" fab dark small @click="Delete(item)">
                <v-icon>fa fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      Wage: {
        UserId: this.$route.query.UserId,
        WageDate: "",
        WageTotal: "0",
        WageRecive: "0",
        WageRemark: "",
      },
      UserId: this.$route.query.UserId,
      desserts: [],
      headers: [
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "Date",
        },
        {
          text: "จำนวนเงิน",
          align: "left",
          sortable: true,
          value: "Total",
          width: 100,
        },
        {
          text: "รับเงิน",
          align: "left",
          sortable: true,
          value: "Paid",
          width: 80,
        },
        {
          text: "คงเหลือ",
          align: "right",
          sortable: true,
          value: "Balance",
          width: 80,
        },
        // {
        //   text: "",
        //   align: "right",
        //   sortable: true,
        //   value: "action",
        //   width: 80,
        // },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select(this.UserId);
    });
  },
  methods: {
    ValidateNumber(){
      if(Number(this.Wage.WageRecive) > Number(this.Wage.WageTotal)){
        this.Wage.WageRecive = this.Wage.WageTotal;
      }
    },
    Insert() {
      axios.post("/api/v1/insert/insert/wage", this.Wage).then((res) => {
        if (res.status == 200) {
          this.Prints(res.data)
          this.Select(this.UserId);
        }
      });
    },
    Select(v) {
      axios
        .get("/api/v1/select/SelectByIdUser/wage?UserId=" + v)
        .then((res) => {
          if (res.status == 200) {
            this.desserts = res.data;
          }
        });
    },
    Prints(v){
      axios.get(`/api/v1/FinanceWage/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
    Delete(v) {
      this.PassAccept("delete", () => {
        axios
        .delete("/api/v1/delete/delete/wage?WageId=" + v.Wage.WageId)
        .then((res) => {
          if (res.status == 200) {
            this.Select(this.UserId);
          }
        });
      });
    },
  },
};
</script>
